import React from 'react';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import buildSpecializationsGridColDefs from './specializations-grid-defs-builder';
import { SPECIALIZATION_TITLE_WIDTH, CV_WIDTH, EDIT_ICON_WIDTH } from './grid-constants';
import { Specialization } from './types';

interface SpecializationsGridProps {
  specializations: Array<Specialization>;
  isInDeleteMode: boolean;
  onDelete: (specialization: Specialization) => void;
  onUpdate: (specialization: Specialization) => void;
  onEditIconClick: (specialization: Specialization) => void;
  enableShowInCv: boolean;
  readOnly?: boolean;
}

const SpecializationsGrid: React.FC<SpecializationsGridProps> = ({
  specializations,
  isInDeleteMode,
  onDelete,
  onUpdate,
  onEditIconClick,
  enableShowInCv,
  readOnly = false,
}) => (
  <Box>
    <DataGridPro
      columns={buildSpecializationsGridColDefs({
        isInDeleteMode,
        onDelete,
        onUpdate,
        onEditIconClick,
        sizes: {
          titleWidth: SPECIALIZATION_TITLE_WIDTH,
          cvWidth: CV_WIDTH,
          editIconWidth: EDIT_ICON_WIDTH,
        },
        enableShowInCv,
        readOnly,
      })}
      autoHeight={true}
      rows={specializations}
      disableColumnMenu={true}
      disableColumnFilter={true}
      disableColumnReorder={true}
      disableExtendRowFullWidth={true}
      hideFooter={true}
      getRowId={(row) => row.id}
      experimentalFeatures={{ newEditingApi: true }}
      {...(!enableShowInCv && { headerHeight: 0 })}
      sx={{
        '&.MuiDataGrid-root': {
          border: 'none',
          borderRadius: 0,
        },
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
          borderRight: 'none',
          backgroundColor: '#F5F5F5',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
          borderRadius: 0,
          backgroundColor: '#F5F5F5',
          ...(!enableShowInCv && { display: 'none' }),
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
          borderRight: 'none',
        },
      }}
    />
  </Box>
);

export default SpecializationsGrid;
