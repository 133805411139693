import React, { useMemo } from 'react';
import { Box } from '@mui/material';

import { useFetchConstants } from 'api';
import { makeSelectItems } from 'utils';

import buildSkillsGridColDefs from './skills-grid-defs-builder';
import { SKILL_NAME_WIDTH, YEARS_WIDTH, CHECKBOX_WIDTH } from './grid-constants';
import { TechStackSkill } from './types';
import { SkillsGridStyled } from './skills-grid.styled';

const isSkillChanged = (newSkill: TechStackSkill, oldSkill: TechStackSkill): boolean => {
  return (
    newSkill.name !== oldSkill.name ||
    newSkill.years !== oldSkill.years ||
    newSkill.main !== oldSkill.main ||
    newSkill.show_in_cv !== oldSkill.show_in_cv
  );
};

interface SkillsGridProps {
  skills: Array<TechStackSkill>;
  isInDeleteMode: boolean;
  onDelete: (deletedSkill: TechStackSkill) => void;
  onUpdate: (updatedSkill: TechStackSkill, originalSkill: TechStackSkill) => void;
  isUpdating: boolean;
  enableShowInCv: boolean;
  readOnly?: boolean;
}

const SkillsGrid: React.FC<SkillsGridProps> = ({
  skills,
  isInDeleteMode,
  onDelete,
  onUpdate,
  isUpdating,
  enableShowInCv,
  readOnly,
}) => {
  const { data: constants } = useFetchConstants();
  const skillItems = useMemo(() => makeSelectItems(constants?.skillsList || []), [constants]);

  return (
    <Box>
      <SkillsGridStyled
        columns={buildSkillsGridColDefs({
          isInDeleteMode,
          onDelete: onDelete,
          onUpdate: onUpdate,
          sizes: {
            nameWidth: SKILL_NAME_WIDTH,
            yearWidth: YEARS_WIDTH,
            flagWidth: CHECKBOX_WIDTH,
          },
          enableShowInCv,
          skillItems: skillItems,
          readOnly,
        })}
        isInDeleteMode={isInDeleteMode}
        autoHeight={true}
        rows={skills}
        disableColumnMenu={true}
        disableColumnFilter={true}
        disableColumnReorder={true}
        disableExtendRowFullWidth={true}
        hideFooter={true}
        getRowId={(row) => row.id}
        experimentalFeatures={{ newEditingApi: true }}
        loading={isUpdating}
        processRowUpdate={(newSkill, oldSkill) => {
          const updatedSkill = newSkill as TechStackSkill;
          const originalSkill = oldSkill as TechStackSkill;
          if (isSkillChanged(updatedSkill, originalSkill)) {
            onUpdate(updatedSkill, originalSkill);
            return newSkill;
          }
          return oldSkill;
        }}
      />
    </Box>
  );
};

export default SkillsGrid;
