import { Box, Typography, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const WizardContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderTop: `1px solid rgba(0, 0, 0, 0.12);`,
  paddingTop: '8px',
}));

export const SkillInput = styled(TextField)(() => ({
  input: {
    paddingLeft: '0px',
    paddingRight: '0px',
    border: 'none',
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
      fontStyle: 'italic',
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
}));

export const WizardOperation = styled(Typography)<{ disabled: boolean }>(({ disabled }) => ({
  paddingRight: '16px',
  cursor: disabled ? 'auto' : 'pointer',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'black',
}));
