import React from 'react';
import AddIcon from '@mui/icons-material/Add';

import { ConfirmModal, Loader, SidebarSection } from 'components';

import { DeleteButton, EditButton } from './buttons';
import SpecializationsGrid from './specializations-grid';
import SpecializationModal, { SpecializationModalProps } from './specialization-modal';
import { Specialization } from './types';

export interface SpecializationsProps {
  specializations: Array<Specialization>;
  isLoading: boolean;
  onDeleteSpecialization: (specialization: Specialization) => void;
  onUpdateSpecialization: (specialization: Specialization) => void;
  onModalCreateSpecialization: SpecializationModalProps['onCreateSpecialization'];
  onModalUpdateSpecialization: SpecializationModalProps['onUpdateSpecialization'];
  isModalLoading: boolean;
  noPadding?: boolean;
  enableShowInCv?: boolean;
  readOnly?: boolean;
}

const Specializations = ({
  specializations,
  isLoading,
  onDeleteSpecialization,
  onUpdateSpecialization,
  onModalCreateSpecialization,
  onModalUpdateSpecialization,
  isModalLoading,
  noPadding = false,
  enableShowInCv = true,
  readOnly = false,
}: SpecializationsProps) => {
  const [isInDeleteMode, setIsInDeleteMode] = React.useState<boolean>(false);
  const [specializationModalIsOpen, setSpecializationModalIsOpen] = React.useState<boolean>(false);
  const [specializationToDelete, setSpecializationToDelete] = React.useState<Specialization | null>(null);
  const [specializationToEdit, setSpecializationToEdit] = React.useState<Specialization | null>(null);

  const toggleSpecializationsGridMode = () => setIsInDeleteMode(!isInDeleteMode);

  const handleOpenDeletePopup = (specialization: Specialization) => {
    setSpecializationToDelete(specialization);
  };

  const handleDeleteSpecialization = () => {
    if (specializationToDelete) {
      onDeleteSpecialization(specializationToDelete);

      setSpecializationToDelete(null);
    }
  };

  const handleInlineEditSpecialization = (specialization: Specialization): void => {
    onUpdateSpecialization(specialization);
  };

  if (isLoading) {
    return <Loader open={true} />;
  }

  const handleSpecializationModalClose = () => {
    setSpecializationModalIsOpen(false);
    setSpecializationToEdit(null);
  };

  const handleEditIconClick = (specialization: Specialization) => {
    setSpecializationModalIsOpen(true);
    setSpecializationToEdit(specialization);
  };

  return (
    <SidebarSection
      noPadding={noPadding}
      title="Specialization"
      buttons={[
        {
          title: 'Add',
          onClick: () => setSpecializationModalIsOpen(true),
          btnVariant: 'secondary',
          icon: <AddIcon />,
          disabled: readOnly,
        },
      ]}
      headerOtherContent={
        isInDeleteMode ? (
          <EditButton onClick={toggleSpecializationsGridMode} disabled={readOnly} />
        ) : (
          <DeleteButton onClick={toggleSpecializationsGridMode} disabled={readOnly} />
        )
      }
    >
      <SpecializationsGrid
        specializations={specializations}
        isInDeleteMode={isInDeleteMode}
        onDelete={handleOpenDeletePopup}
        onUpdate={handleInlineEditSpecialization}
        onEditIconClick={handleEditIconClick}
        enableShowInCv={enableShowInCv}
        readOnly={readOnly}
      />
      <ConfirmModal
        isOpen={!!specializationToDelete}
        onClose={() => setSpecializationToDelete(null)}
        onConfirm={handleDeleteSpecialization}
        title="Sure you wanna delete this specialization?"
        content="It will disappear once and for all. Delete it anyway?"
        confirmLabel="Delete specialization"
        inverse
      />
      <SpecializationModal
        isOpen={specializationModalIsOpen}
        specialization={specializationToEdit}
        onCreateSpecialization={onModalCreateSpecialization}
        onUpdateSpecialization={onModalUpdateSpecialization}
        isModalLoading={isModalLoading}
        onClose={handleSpecializationModalClose}
        enableShowInCv={enableShowInCv}
      />
    </SidebarSection>
  );
};

export default Specializations;
