import React, { memo, useMemo } from 'react';
import { SxProps } from '@mui/material';

import { Autocomplete } from 'components';
import { useFindTags } from 'api';
import { SelectItem } from 'types';

interface TagNameInputProps {
  sx?: SxProps;
  label: string;
  value?: string;
  onChange: (value: string | null) => void;
  error?: boolean;
  autoFocus?: boolean;
  categories?: string[];
  groups?: string[];
  sorting?: Record<string, 'ASC' | 'DESC'>;
  dataTestId?: string;
}

const TagAutocomplete = ({
  sx,
  label,
  value,
  onChange,
  error,
  autoFocus,
  categories,
  groups,
  sorting = { group: 'ASC', name: 'ASC' },
  dataTestId,
}: TagNameInputProps) => {
  const { findTagsResult } = useFindTags({
    filters: {
      isDeleted: false,
      ...(!!categories && { categories }),
      ...(!!groups && { groups }),
    },
    sorting,
    pagination: { page: 1, perPage: 10000 },
  });

  const tagItems = useMemo<Array<SelectItem<string>>>(
    () => (findTagsResult?.tags ?? []).map((tag) => ({ name: tag.name, value: tag.id })),
    [findTagsResult]
  );

  return (
    <Autocomplete
      sx={sx}
      label={label}
      color="primary"
      variant="standard"
      items={tagItems}
      value={value}
      onChange={onChange}
      error={error}
      autoFocus={autoFocus}
      dataTestId={dataTestId}
    />
  );
};

export default memo(TagAutocomplete);
