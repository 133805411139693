import Image from 'next/image';
import { Button, Container, Box, Typography } from '@mui/material';
import { Theme } from '@mui/system';

import { oldCrmUrl } from 'config';
import { CopyButton } from 'components';

const IMAGE_SIZE = 650;

interface Props {
  profile?: boolean;
  candidateId?: string;
  traceId?: string;
}

const ErrorPage = ({ profile = false, candidateId = '', traceId }: Props) => (
  <Container
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      height: '100vh',
    }}
  >
    <Image src="/kraken.png" height={IMAGE_SIZE} alt="terrifying kraken with beer" width={IMAGE_SIZE} />
    <Box sx={{ width: `${IMAGE_SIZE}px` }}>
      <Typography
        component="p"
        sx={{
          pr: '64px',
          mb: '32px',
          fontSize: '40px',
          color: (theme: Theme) => theme.palette.primary.main,
          fontWeight: 500,
        }}
      >
        What happened?
      </Typography>
      <Typography
        component="p"
        sx={{
          pr: '64px',
          ...(profile && { mb: '24px' }),
          fontSize: '22px',
          color: (theme: Theme) => theme.palette.primary.main,
          fontWeight: 500,
        }}
      >
        {profile ? (
          <>
            Something went wrong with opening this candidate profile. By clicking the button bellow you’ll be navigated
            to the old profile of this candidate.
          </>
        ) : (
          <>
            Oh dears... Kraken broken some piece of our absolutely properly working functionality... again! We have been
            notified about his misbehavior and are already working hard on cleaning stuff out - in fact, as you can see,
            he has stolen our beer!
          </>
        )}
      </Typography>
      {profile && (
        <Box>
          <Button
            sx={{ fontSize: '18px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              window.open(`${oldCrmUrl}/contact/${candidateId}`);
            }}
          >
            Old profile
          </Button>
        </Box>
      )}
      {traceId && (
        <Box sx={{ display: 'flex', alignItems: 'center', pr: '64px', mt: '24px' }}>
          <CopyButton sx={{ mr: '8px' }} value={traceId} />
          <Typography component="p" sx={{ fontSize: '16px' }}>
            <Box sx={{ fontWeight: 500 }} component="span">
              traceId:
            </Box>{' '}
            {traceId}
          </Typography>
        </Box>
      )}
    </Box>
  </Container>
);

export default ErrorPage;
