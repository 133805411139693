import React, { memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z as zod } from 'zod';
import { Box } from '@mui/material';

import { FlexColumn, FlexRow } from 'components';
import { Content, Title, Actions } from 'components/modal-form';

import { type SpecializationFormValues } from './specialization-form.types';
import { useFormFields } from './specialization-form.hooks';
import { Specialization } from '../types';

interface OnCreateSpecializationOptions {
  hasCustomTitle: boolean;
  onSuccess: () => void;
}

interface OnUpdateSpecializationOptions {
  hasCustomTitle: boolean;
  onSuccess: () => void;
  specialization: Specialization;
}

export interface SpecializationFormProps {
  specialization: Specialization | null;
  onCreateSpecialization: (formValues: SpecializationFormValues, options: OnCreateSpecializationOptions) => void;
  onUpdateSpecialization: (formValues: SpecializationFormValues, options: OnUpdateSpecializationOptions) => void;
  onCancel: () => void;
  onSuccess: () => void;
  isFormLoading: boolean;
  enableShowInCv: boolean;
}

const SpecializationForm = ({
  specialization,
  onCreateSpecialization,
  onUpdateSpecialization,
  onCancel,
  onSuccess,
  isFormLoading,
  enableShowInCv,
}: SpecializationFormProps) => {
  const schema = useMemo(
    () =>
      zod
        .object({
          title: zod.string().min(1),
          seniority: zod.string().optional(),
          customTitle: zod.string(),
          ...(enableShowInCv && { showInCv: zod.boolean() }),
        })
        .superRefine(({ title, customTitle }, ctx) => {
          if (title === 'Other' && !customTitle.trim()) {
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              path: ['customTitle'],
            });
          }
        }),
    [enableShowInCv]
  );

  const defaultValues = useMemo<SpecializationFormValues>(
    () => ({
      title: specialization && specialization.has_custom_title ? 'Other' : specialization?.title || '',
      seniority: specialization?.seniority ?? '',
      showInCv: specialization ? specialization.show_in_cv ?? true : true,
      customTitle: specialization?.has_custom_title ? specialization.title ?? '' : '',
    }),
    [specialization]
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<SpecializationFormValues>({ defaultValues, resolver: zodResolver(schema), mode: 'onChange' });

  const submit = handleSubmit((data: SpecializationFormValues) => {
    const hasCustomTitle = data.title === 'Other';
    if (!specialization) {
      onCreateSpecialization(data, { hasCustomTitle, onSuccess });
      return;
    }

    onUpdateSpecialization(data, { hasCustomTitle, onSuccess, specialization });
  });

  const fields = useFormFields({ control, enableShowInCv });

  return (
    <Box>
      <Content>
        <Title sx={{ mb: '24px' }}>{specialization ? 'Edit specialization' : 'Add specialization'}</Title>
        <FlexColumn sx={{ gap: '24px' }}>
          {fields.map((field, i) => (
            <Box key={i}>
              {Array.isArray(field) ? (
                <FlexRow sx={{ gap: '24px' }}>
                  <Box sx={{ flex: 1 }}>{field[0]}</Box>
                  <Box sx={{ flex: 1 }}>{field[1]}</Box>
                </FlexRow>
              ) : (
                field
              )}
            </Box>
          ))}
        </FlexColumn>
      </Content>
      <Actions
        submitLabel={specialization ? 'Edit specialization' : 'Add specialization'}
        onCancel={onCancel}
        onSubmit={submit}
        disabled={!isValid || isFormLoading}
      />
    </Box>
  );
};

export default memo(SpecializationForm);
