import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const SkillsGridStyled = styled(DataGridPro)<{ isInDeleteMode: boolean }>((isInDeleteMode) => ({
  ...(isInDeleteMode
    ? {}
    : {
        '&.MuiDataGrid-root': {
          borderRadius: 0,
          borderBottom: 'none',
        },
      }),
  '&.MuiDataGrid-root': {
    border: 'none',
    borderRadius: 0,
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    borderRight: 'none',
    backgroundColor: '#F5F5F5',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
    borderRadius: 0,
    backgroundColor: '#F5F5F5',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    borderRight: 'none',
  },
}));

export const GridContainerStyled = styled(Box)(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
}));
