import React from 'react';
import { Checkbox, IconButton } from '@mui/material';
import { GridEnrichedColDef } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';

import { TechStackSkill } from './types';
import SkillCell from './skill-cell';
import { SelectItem } from 'types/app';
import SkillEditCell from './skill-edit-cell';

interface SkillCheckboxProps {
  initValue: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const SkillCheckbox: React.FC<SkillCheckboxProps> = ({ initValue, onChange, disabled }) => {
  const [checked, setChecked] = React.useState<boolean>(initValue);
  React.useEffect(() => setChecked(initValue), [initValue]);

  const handleChange = (newValue: boolean) => {
    setChecked(newValue);
    onChange(newValue);
  };

  return (
    <Checkbox
      size="small"
      color="primary"
      checked={checked}
      onChange={(_, newValue) => handleChange(newValue)}
      disabled={disabled}
    />
  );
};
interface BuilderProps {
  isInDeleteMode: boolean;
  sizes: {
    nameWidth: number;
    yearWidth: number;
    flagWidth: number;
  };
  onDelete: (deletedSkill: TechStackSkill) => void;
  onUpdate: (updatedSkill: TechStackSkill, originalSkill: TechStackSkill) => void;
  enableShowInCv: boolean;
  skillItems: Array<SelectItem<string>>;
  readOnly?: boolean;
}

const colDefsBuilder = ({
  isInDeleteMode,
  sizes,
  onDelete,
  onUpdate,
  enableShowInCv,
  skillItems,
  readOnly,
}: BuilderProps): GridEnrichedColDef[] => {
  if (!isInDeleteMode) {
    const { nameWidth, yearWidth, flagWidth } = sizes;
    return [
      {
        field: 'name',
        headerName: 'Skill',
        width: nameWidth + (!enableShowInCv ? 35 : 0),
        editable: !readOnly,
        align: 'left',
        sortable: false,
        resizable: false,
        disableReorder: true,
        renderEditCell: (params) => <SkillEditCell {...params} skillItems={skillItems} />,
        renderCell: (params) => <SkillCell skillName={params.row.name} />,
      },
      {
        field: 'years',
        headerName: 'Year',
        width: yearWidth + (!enableShowInCv ? 35 : 0),
        headerAlign: 'left',
        align: 'left',
        editable: !readOnly,
        sortable: false,
        resizable: false,
        disableReorder: true,
      },
      ...(enableShowInCv
        ? [
            {
              field: 'show_in_cv',
              headerName: 'CV',
              width: flagWidth,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              resizable: false,
              disableReorder: true,
              renderCell: ({ row }) => {
                return (
                  <SkillCheckbox
                    initValue={row.show_in_cv ?? false}
                    onChange={(newValue) => onUpdate({ ...row, show_in_cv: newValue }, row)}
                    disabled={readOnly}
                  />
                );
              },
            } as GridEnrichedColDef,
          ]
        : []),
      {
        field: 'main',
        headerName: 'Main',
        width: flagWidth,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        resizable: false,
        disableReorder: true,
        renderCell: ({ row }) => {
          return (
            <SkillCheckbox
              initValue={row.main}
              onChange={(newValue) => onUpdate({ ...row, main: newValue }, row)}
              disabled={readOnly}
            />
          );
        },
      },
    ];
  }

  return [
    {
      field: '',
      headerName: '',
      width: 20,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      sortable: false,
      resizable: false,
      disableReorder: true,
      renderCell: ({ row }) => {
        return (
          <IconButton aria-label="delete" size="small" color="primary" onClick={() => onDelete(row)}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Skill',
      width: 250,
      align: 'left',
      sortable: false,
      resizable: false,
      disableReorder: true,
      renderCell: (params) => <SkillCell skillName={params.row.name} />,
    },
  ];
};

export default colDefsBuilder;
