import React, { memo } from 'react';

import { Modal } from 'components';

import SpecializationForm, { SpecializationFormProps } from './specialization-form';
import { Specialization } from '../types';

export interface SpecializationModalProps {
  isOpen: boolean;
  specialization: Specialization | null;
  onCreateSpecialization: SpecializationFormProps['onCreateSpecialization'];
  onUpdateSpecialization: SpecializationFormProps['onUpdateSpecialization'];
  isModalLoading: boolean;
  onClose: () => void;
  enableShowInCv: boolean;
}

const SpecializationModal = ({
  isOpen,
  specialization,
  onCreateSpecialization,
  onUpdateSpecialization,
  isModalLoading,
  onClose,
  enableShowInCv,
}: SpecializationModalProps) => (
  <Modal isOpen={isOpen}>
    <SpecializationForm
      specialization={specialization}
      onCancel={onClose}
      onSuccess={onClose}
      onCreateSpecialization={onCreateSpecialization}
      onUpdateSpecialization={onUpdateSpecialization}
      isFormLoading={isModalLoading}
      enableShowInCv={enableShowInCv}
    />
  </Modal>
);

export default memo(SpecializationModal);
