import React, { useMemo } from 'react';

import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

import { Autocomplete } from 'components';
import { SelectItem } from 'types';

const SkillEditCell = ({
  skillItems,
  ...props
}: GridRenderEditCellParams & { skillItems: Array<SelectItem<string>> }) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (value: string | null) => {
    if (skillItems.some((skillItem) => skillItem.value === value)) {
      apiRef.current.setEditCellValue({ id, field, value });
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  const filteredSkillItems = useMemo(() => {
    const rowIds = Object.keys(apiRef.current.state.rows.tree);
    const rows = rowIds.map((rowId) => apiRef.current.getRow(rowId));
    const existingSkills = rows.map((row) => row.name);

    return skillItems.filter((skillItem) => skillItem.value === value || !existingSkills.includes(skillItem.value));
  }, [apiRef, skillItems, value]);

  return (
    <Autocomplete
      sx={{
        border: 'none',
        marginLeft: '10px',
        [`& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before`]: {
          border: 'none',
        },
        [`& input::placeholder`]: {
          fontStyle: 'italic',
          fontSize: '0.9rem',
          color: 'rgba(0, 0, 0, 0.6)',
        },
      }}
      value={value}
      label=""
      placeholder="Skill"
      items={filteredSkillItems}
      onChange={handleValueChange}
    />
  );
};

export default SkillEditCell;
