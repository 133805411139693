import React, { ErrorInfo } from 'react';
import * as Sentry from '@sentry/nextjs';

import { APIError } from 'api';

import ErrorPage from './error-page';

interface Props {
  profile?: boolean;
  candidateId?: string;
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  traceId?: string;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error instanceof APIError) {
      this.setState({ traceId: error.traceId });
    }

    Sentry.captureException(error, { extra: errorInfo as unknown as Record<string, string> });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage profile={this.props.profile} candidateId={this.props.candidateId} traceId={this.state.traceId} />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
