import React, { memo } from 'react';

import { Modal } from 'components';
import { Candidate } from 'types';

import OfferProjectForm from './offer-project-form';

interface Props {
  isOpen: boolean;
  candidate: Candidate;
  hubspotProjectId?: string;
  onClose: () => void;
}

const EducationModal = ({ isOpen, candidate, hubspotProjectId, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <OfferProjectForm
        candidate={candidate}
        hubspotProjectId={hubspotProjectId}
        onCancel={onClose}
        onSuccess={onClose}
      />
    </Modal>
  );
};

export default memo(EducationModal);
