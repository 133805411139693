import React, { useState, memo } from 'react';

import { Loader, SidebarSection } from 'components';

import SkillsGrid from './skills-grid';
import SkillWizard from './skill-wizard';
import { TechStackSkill } from './types';
import { DeleteButton, EditButton } from './buttons';

export interface TechStackProps {
  skills: Array<TechStackSkill>;
  isLoading: boolean;
  onAddSkill: (skill: TechStackSkill, params: { setIsUpdating: (value: boolean) => void }) => void;
  onDeleteSkill: (skill: TechStackSkill, params: { setIsUpdating: (value: boolean) => void }) => void;
  onUpdateSkill: (
    updatedSkill: TechStackSkill,
    originalSkill: TechStackSkill,
    params: { setIsUpdating: (value: boolean) => void }
  ) => void;
  noPadding?: boolean;
  enableShowInCv?: boolean;
  readOnly?: boolean;
}

const TechStack = ({
  skills,
  isLoading,
  onAddSkill,
  onDeleteSkill,
  onUpdateSkill,
  noPadding = false,
  enableShowInCv = true,
  readOnly = false,
}: TechStackProps) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isInDeleteMode, setIsInDeleteMode] = useState<boolean>(false);

  const toggleSkillsGridMode = () => setIsInDeleteMode(!isInDeleteMode);

  const handleAddSkill = (skill: TechStackSkill) => {
    onAddSkill(skill, { setIsUpdating });
  };

  const handleDeleteSkill = (skill: TechStackSkill) => {
    onDeleteSkill(skill, { setIsUpdating });
  };

  const handleUpdateSkill = (updatedSkill: TechStackSkill, originalSkill: TechStackSkill): void => {
    onUpdateSkill(updatedSkill, originalSkill, { setIsUpdating });
  };

  if (isLoading) {
    return <Loader open={true} />;
  }

  return (
    <SidebarSection
      noPadding={noPadding}
      title="Tech stack"
      headerOtherContent={
        isInDeleteMode ? (
          <EditButton onClick={toggleSkillsGridMode} disabled={readOnly} />
        ) : (
          <DeleteButton onClick={toggleSkillsGridMode} disabled={readOnly} />
        )
      }
    >
      <SkillsGrid
        skills={skills}
        isInDeleteMode={isInDeleteMode}
        onDelete={handleDeleteSkill}
        onUpdate={handleUpdateSkill}
        isUpdating={isUpdating}
        enableShowInCv={enableShowInCv}
        readOnly={readOnly}
      />
      {!isInDeleteMode && !readOnly && (
        <SkillWizard skills={skills} onAdd={handleAddSkill} enableShowInCv={enableShowInCv} />
      )}
    </SidebarSection>
  );
};

export default memo(TechStack);
