import React, { useMemo } from 'react';
import { Box, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';

import { FlexCenteredCell, FlexRow, Autocomplete } from 'components';
import { SelectItem } from 'types';
import { useFetchConstants } from 'api';

import { TechStackSkill } from './types';
import { SKILL_NAME_WIDTH, YEARS_WIDTH, CHECKBOX_WIDTH } from './grid-constants';
import { WizardContainer, WizardOperation, SkillInput } from './skill-wizard.styled';

interface SkillWizardProps {
  skills: Array<TechStackSkill>;
  onAdd: (skill: TechStackSkill) => void;
  enableShowInCv: boolean;
}

interface State {
  name: string;
  years: string;
  isInCv: boolean;
  isMain: boolean;
}

const initialState: State = {
  name: '',
  years: '',
  isInCv: true,
  isMain: false,
};

const SkillWizard: React.FC<SkillWizardProps> = ({ skills, onAdd, enableShowInCv }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: constants } = useFetchConstants();

  const [{ name, years, isMain, isInCv }, updateState] = React.useReducer(
    (state: State, payload: Partial<State>): State => {
      return {
        ...state,
        ...payload,
      };
    },
    initialState
  );

  const technologyInputRef = React.useRef<HTMLElement>();

  const normalizedSkillNames = React.useMemo(
    () =>
      skills
        .map(({ name }) => name.trim())
        .map((skillName) =>
          skillName
            .split(' ')
            .filter((token) => token !== ' ')
            .map((token) => token.toLowerCase())
            .filter((token) => token !== '')
            .join(' ')
        ),
    [skills]
  );

  const handleNameChange: (name: string | null) => void = (name) => {
    updateState({ name: name || '' });
  };

  /**
   * since the Years input is very thin, displaying the number controls inside it (if set the input type to number),
   * make UI ugly, so this change value handler is responsible for filtering out non-digits.
   */
  const handleYearsChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const yearsNumberRegex = /^[0-9]{0,2}\.{0,1}[5]{0,1}$/;
    if (!event.target.value.match(yearsNumberRegex)) {
      return;
    }

    updateState({ years: event.target.value });
  };

  const isNameEmpty = React.useCallback(() => {
    return name === '' || name.trim().length === 0;
  }, [name]);

  const handleAddSkill = () => {
    if (isNameEmpty()) {
      return;
    }

    if (normalizedSkillNames.includes(name.toLowerCase().trim())) {
      enqueueSnackbar(`The ${name} skill is already in the list.`, { variant: 'error' });
      return;
    }
    onAdd({
      name,
      years: years === '' ? null : years,
      main: isMain,
      show_in_cv: isInCv,
    });
    updateState(initialState);

    technologyInputRef?.current?.focus();
  };

  const handleClearTechnologiesBtnKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.code === 'Enter') {
      handleResetSkill();
      technologyInputRef?.current?.focus();
    }
  };

  const handleAddTechnologyBtnKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.code === 'Enter') {
      handleAddSkill();
    }
  };

  const handleResetSkill = () => updateState(initialState);

  const skillItems = useMemo(
    () =>
      (constants?.skillsList || [])
        .filter((skill) => !normalizedSkillNames.includes(skill.toLowerCase()))
        .map<SelectItem<string>>((skill) => ({ name: skill, value: skill })),
    [constants, normalizedSkillNames]
  );

  return (
    <WizardContainer>
      <FlexRow>
        <FlexCenteredCell
          sx={{ marginLeft: '10px', width: `${SKILL_NAME_WIDTH + (!enableShowInCv ? 35 : 0)}px`, pr: '4px' }}
        >
          <Autocomplete
            sx={{
              border: 'none',
              [`& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before`]: {
                border: 'none',
              },
              [`& input::placeholder`]: {
                fontStyle: 'italic',
                fontSize: '0.9rem',
                color: 'rgba(0, 0, 0, 0.6)',
              },
            }}
            label=""
            placeholder="Skill"
            onChange={handleNameChange}
            color="primary"
            variant="standard"
            items={skillItems}
            value={name}
          />
        </FlexCenteredCell>
        <FlexCenteredCell sx={{ width: `${YEARS_WIDTH + (!enableShowInCv ? 35 : 0)}px` }}>
          <SkillInput
            hiddenLabel
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            size="small"
            color="primary"
            value={years}
            onChange={handleYearsChange}
            placeholder="Years..."
          />
        </FlexCenteredCell>
        {enableShowInCv && (
          <FlexCenteredCell sx={{ width: `${CHECKBOX_WIDTH}px` }}>
            <Checkbox
              sx={{ marginLeft: '-15px' }}
              checked={isInCv}
              color="primary"
              onChange={(_, checked) => updateState({ isInCv: checked })}
            />
          </FlexCenteredCell>
        )}
        <FlexCenteredCell sx={{ width: `${CHECKBOX_WIDTH}px` }}>
          <Checkbox
            sx={{ marginLeft: '-15px' }}
            checked={isMain}
            color="primary"
            onChange={(_, checked) => updateState({ isMain: checked })}
          />
        </FlexCenteredCell>
      </FlexRow>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row-reverse',
          py: '8px',
          borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
        }}
      >
        <WizardOperation disabled={isNameEmpty()} onClick={handleAddSkill}>
          <span tabIndex={0} onKeyDown={handleAddTechnologyBtnKeyDown}>
            Add technology
          </span>
        </WizardOperation>
        <WizardOperation disabled={isNameEmpty()} onClick={handleResetSkill}>
          <span tabIndex={0} onKeyDown={handleClearTechnologiesBtnKeyDown}>
            Clear
          </span>
        </WizardOperation>
      </Box>
    </WizardContainer>
  );
};

export default SkillWizard;
