import React from 'react';
import { Tooltip, Typography, Box } from '@mui/material';

interface Props {
  skillName: string;
}

const SkillName: React.FC<Props> = ({ skillName }) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean>(false);
  const textRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (textRef.current && textRef.current.scrollWidth > textRef.current.offsetWidth) {
      setIsOverflow(true);
    }
  }, [textRef]);

  if (isOverflow) {
    return (
      <Tooltip placement="bottom-start" title={<Typography>{skillName}</Typography>}>
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {skillName}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography ref={textRef}>{skillName}</Typography>
    </Box>
  );
};

export default SkillName;
