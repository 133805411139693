import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';

import { Autocomplete, AutocompleteProps } from 'components';
import { useFetchHubspotProjectByProjectId, useFindHubspotProjects } from 'api';
import { SelectItem } from 'types';
import { useDebounce } from 'hooks';

type ProjectInputProps = Omit<AutocompleteProps, 'items' | 'onInputChange' | 'filterOptions'> & {
  nameProperty: 'dealName' | 'projectName';
};

const ProjectInput = ({ onChange, nameProperty, ...props }: ProjectInputProps) => {
  const { hubspotProject } = useFetchHubspotProjectByProjectId({ hubspotProjectId: props.value ?? null });

  const hubspotProjectName = useMemo(() => hubspotProject?.[nameProperty] ?? '', [hubspotProject, nameProperty]);

  const [searchTerm, setSearchTerm] = useState<string>(hubspotProject ? hubspotProjectName : '');
  const { hubspotProjects } = useFindHubspotProjects({
    filters: { ...(searchTerm && { [nameProperty]: searchTerm }) },
    pagination: {
      page: 1,
      perPage: 10,
    },
    sorting: {},
  });

  const items = useMemo<Array<SelectItem<string>>>(
    () =>
      (hubspotProjects ? hubspotProjects.projects : []).map((project) => ({
        value: project.id,
        name: project[nameProperty],
      })),
    [hubspotProjects, nameProperty]
  );

  const handleInputChange = useDebounce((term: string) => {
    setSearchTerm(term);
  }, 100);

  const handleChange = useCallback(
    (value: string | null) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  useEffect(() => {
    setSearchTerm(hubspotProject ? hubspotProjectName : '');
  }, [hubspotProject, hubspotProjectName]);

  return (
    <Autocomplete
      {...props}
      onChange={handleChange}
      items={items}
      onInputChange={handleInputChange}
      filterOptions={(options) => options}
    />
  );
};

export default memo(ProjectInput);
