import React, { memo, useMemo } from 'react';
import { SxProps } from '@mui/material';

import { MultiAutocomplete, TagChip } from 'components';
import { useFindTags } from 'api';

import { SelectItem } from 'types';

interface TagMultiAutocompleteProps {
  sx?: SxProps;
  label: string;
  value: Array<string>;
  onChange: (value: Array<string>) => void;
  onBlur?: () => void;
  error?: boolean | string;
  groups?: string[];
  dataTestId?: string;
  variant?: 'standard' | 'outlined';
}

const TagMultiAutocomplete = ({
  sx,
  label,
  value,
  onChange,
  onBlur,
  error,
  groups,
  dataTestId,
  variant = 'standard',
}: TagMultiAutocompleteProps) => {
  const { findTagsResult } = useFindTags({
    filters: {
      isDeleted: false,
      ...(!!groups && { groups }),
    },
    pagination: { page: 1, perPage: 10000 },
  });

  const tagItems = useMemo<Array<SelectItem<string>>>(
    () => (findTagsResult?.tags ?? []).map((tag) => ({ name: tag.name, value: tag.id })),
    [findTagsResult]
  );

  return (
    <MultiAutocomplete
      sx={sx}
      label={label}
      color="primary"
      chipColor="primary"
      variant={variant}
      items={tagItems}
      value={findTagsResult?.tags ? value : []}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      renderTags={() =>
        value
          .map((tagId) => {
            const group = (findTagsResult?.tags || []).find((t) => t.id === tagId)?.group;
            return group ? (
              <TagChip
                key={tagId}
                sx={{ margin: '4px' }}
                type={group}
                label={tagItems.find((item) => item.value === tagId)?.name ?? ''}
                onDelete={() => onChange(value.filter((t) => t !== tagId))}
                dataTestId={`${dataTestId}__tag-chip`}
              />
            ) : null;
          })
          .filter((tc) => !!tc)
      }
      dataTestId={dataTestId}
    />
  );
};

export default memo(TagMultiAutocomplete);
