import React from 'react';
import { Checkbox, IconButton } from '@mui/material';
import { GridEnrichedColDef } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { CandidateSpecialization } from 'types/domain';

import { Specialization } from './types';

interface SpecializationCheckboxProps {
  initValue: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const SpecializationCheckbox: React.FC<SpecializationCheckboxProps> = ({ initValue, onChange, disabled }) => {
  const [checked, setChecked] = React.useState<boolean>(initValue);
  React.useEffect(() => setChecked(initValue), [initValue]);

  const handleChange = (newValue: boolean) => {
    setChecked(newValue);
    onChange(newValue);
  };

  return (
    <Checkbox
      size="small"
      color="primary"
      checked={checked}
      onChange={(_, newValue) => handleChange(newValue)}
      disabled={disabled}
    />
  );
};
interface BuilderProps {
  isInDeleteMode: boolean;
  sizes: {
    titleWidth: number;
    cvWidth: number;
    editIconWidth: number;
  };
  onDelete: (specialization: Specialization) => void;
  onUpdate: (specialization: Specialization) => void;
  onEditIconClick: (specialization: Specialization) => void;
  enableShowInCv: boolean;
  readOnly?: boolean;
}

const colDefsBuilder = ({
  isInDeleteMode,
  sizes,
  onDelete,
  onUpdate,
  onEditIconClick,
  enableShowInCv,
  readOnly,
}: BuilderProps): GridEnrichedColDef[] => {
  const renderTitleCell = ({ row }: { row: CandidateSpecialization }) => {
    return (row.seniority ? row.seniority + ' ' : '') + row.title;
  };

  if (!isInDeleteMode) {
    const { titleWidth, cvWidth, editIconWidth } = sizes;
    return [
      {
        field: 'title',
        headerName: 'Title',
        width: enableShowInCv
          ? titleWidth
          : titleWidth + cvWidth + (!enableShowInCv ? 27 : 0) /* 27 Magic number (O_o) */,
        align: 'left',
        sortable: false,
        resizable: false,
        disableReorder: true,
        renderCell: renderTitleCell,
      },
      ...(enableShowInCv
        ? [
            {
              field: 'show_in_cv',
              headerName: 'CV',
              width: cvWidth,
              align: 'center',
              headerAlign: 'center',
              sortable: false,
              resizable: false,
              disableReorder: true,
              renderCell: ({ row }) => {
                return (
                  <SpecializationCheckbox
                    initValue={row.show_in_cv}
                    onChange={(newValue) => onUpdate({ ...row, show_in_cv: newValue })}
                    disabled={readOnly}
                  />
                );
              },
            } as GridEnrichedColDef,
          ]
        : []),
      {
        field: '',
        headerName: '',
        width: editIconWidth,
        align: enableShowInCv ? 'center' : 'right',
        sortable: false,
        resizable: false,
        disableReorder: true,
        renderCell: ({ row }) => {
          return (
            <IconButton
              aria-label="delete"
              size="small"
              color="primary"
              onClick={() => onEditIconClick(row)}
              disabled={readOnly}
            >
              <EditIcon />
            </IconButton>
          );
        },
      },
    ];
  }

  return [
    {
      field: '',
      headerName: '',
      width: 20,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      resizable: false,
      disableReorder: true,
      renderCell: ({ row }) => {
        return (
          <IconButton aria-label="delete" size="small" color="primary" onClick={() => onDelete(row)}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        );
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 240 + (!enableShowInCv ? 27 : 0) /* 27 Magic number (O_o) */,
      align: 'left',
      sortable: false,
      resizable: false,
      disableReorder: true,
      renderCell: renderTitleCell,
    },
  ];
};

export default colDefsBuilder;
