import React, { useMemo, memo, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z as zod } from 'zod';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';

import { AvailabilityType, Candidate, ProjectWeeklyInvolvement, SelectItem } from 'types';
import { useFetchConstants, useAddHubspotProjectCandidate } from 'api';
import { FormInput, Editor, Autocomplete, LinkInput } from 'components';
import { Content, Title, Actions } from 'components/modal-form';
import { AVAILABILITY_TYPE_MAP } from 'constants/candidate';
import { getError, makeSelectItems, validators } from 'utils';

import ProjectInput from '../project-input/project-input';
import { OfferProjectFormValues } from './offer-project-form.types';
import { FieldList } from './offer-project-form.styled';

const schema = zod.object({
  hubspotProjectId: zod.string().refine(validators.string.min({ value: 1, trim: true })),
  involvementType: zod.string().or(zod.null()),
  involvementHours: zod.string(),
  devRate: zod
    .string()
    .refine(validators.string.number({}))
    .refine(validators.string.numberLimit({ min: 1 }))
    .or(zod.string().regex(/^$/)),
  clientRate: zod
    .string()
    .refine(validators.string.number({}))
    .refine(validators.string.numberLimit({ min: 1 }))
    .or(zod.string().regex(/^$/)),
  salesComment: zod
    .string()
    .refine(validators.string.min({ value: 1, trim: true }))
    .or(zod.string().regex(/^$/)),
  summary: zod
    .string()
    .refine(validators.string.min({ value: 1, trim: true }))
    .or(zod.string().regex(/^$/)),
  interviewTimeslots: zod
    .string()
    .refine(validators.string.min({ value: 1, trim: true }))
    .or(zod.string().regex(/^$/)),
  readyToStartWhen: zod
    .string()
    .refine(validators.string.min({ value: 1, trim: true }))
    .or(zod.string().regex(/^$/)),
  rateCommentForDev: zod
    .string()
    .refine(validators.string.min({ value: 1, trim: true }))
    .refine(validators.string.max({ value: 100, trim: true }), 'Keep your comment short - up to 100 symbols')
    .or(zod.string().regex(/^$/)),
  chatURL: zod.string().refine(validators.string.min({ value: 1, trim: true })),
  noteForLog: zod
    .string()
    .refine(validators.string.min({ value: 1, trim: true }))
    .or(zod.string().regex(/^$/)),
});

interface OfferProjectFormProps {
  candidate: Candidate;
  hubspotProjectId?: string;
  onCancel: () => void;
  onSuccess: () => void;
}

const OfferProjectForm = ({ candidate, hubspotProjectId, onCancel, onSuccess }: OfferProjectFormProps) => {
  const { data: constants } = useFetchConstants();

  const { addHubspotProjectCandidate, addHubspotProjectCandidateLoading } = useAddHubspotProjectCandidate();

  const defaultValues = useMemo<OfferProjectFormValues>(
    () => ({
      hubspotProjectId: hubspotProjectId ?? null,
      involvementType: candidate.availability_type ?? null,
      involvementHours: candidate.available_hours_per_week ?? null,
      devRate: '',
      clientRate: '',
      rateCommentForDev: '',
      salesComment: '',
      summary: candidate.professional_intro ?? '',
      interviewTimeslots: '',
      readyToStartWhen: '',
      chatURL: candidate.telegramGroupUrl ?? '',
      noteForLog: '',
    }),
    [candidate, hubspotProjectId]
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<OfferProjectFormValues>({ defaultValues, resolver: zodResolver(schema), mode: 'onChange' });

  const availabilityTypeItems = useMemo<Array<SelectItem<string>>>(
    () =>
      (constants?.availabilityTypes ?? []).map<SelectItem<string>>((type) => ({
        value: type,
        name: AVAILABILITY_TYPE_MAP[type as AvailabilityType]?.label ?? type,
      })),
    [constants]
  );

  const involvementType = watch('involvementType');
  const availabilityHoursItems = useMemo(
    () =>
      makeSelectItems(
        AVAILABILITY_TYPE_MAP[(involvementType as AvailabilityType) || AvailabilityType.Unknown].hoursList
      ),
    [involvementType]
  );

  const { enqueueSnackbar } = useSnackbar();

  const submit = handleSubmit((data) => {
    addHubspotProjectCandidate(
      {
        contactId: candidate.id,
        hubspotProjectId: data.hubspotProjectId as string,
        availabilityType: data.involvementType as AvailabilityType,
        candidateAvailableHoursPerWeek: data.involvementHours as ProjectWeeklyInvolvement,
        clientRatePerHour: data.clientRate ? Number(data.clientRate) : null,
        candidateRatePerHour: data.devRate ? Number(data.devRate) : null,
        rateCommentForDev: data.rateCommentForDev,
        salesComment: data.salesComment,
        summary: data.summary,
        interviewTimeslots: data.interviewTimeslots,
        readyToStartAt: data.readyToStartWhen,
        slackURL: data.chatURL,
        profileLogNote: data.noteForLog,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Project offered', { variant: 'success' });
          onSuccess();
        },
        onError: (error) => {
          const { message } = error as Error;
          enqueueSnackbar(message || 'Failed to offer project', { variant: 'error' });
        },
      }
    );
  });

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === 'involvementType') {
        setValue('involvementHours', null);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  return (
    <Box>
      <Content>
        <Title sx={{ mb: '24px' }}>Offer project</Title>
        <FieldList>
          {!hubspotProjectId && (
            <Controller
              name="hubspotProjectId"
              control={control}
              render={({ field, fieldState }) => (
                <ProjectInput
                  {...field}
                  onChange={field.onChange}
                  error={!!getError(fieldState)}
                  label="Project name"
                  color="primary"
                  autoFocus
                  nameProperty="projectName"
                />
              )}
            />
          )}
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Controller
              name="involvementType"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  onChange={field.onChange}
                  error={!!getError(fieldState)}
                  label="Involvement type"
                  color="primary"
                  items={availabilityTypeItems}
                  withoutClear
                />
              )}
            />
            <Controller
              name="involvementHours"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  label="Involvement hours"
                  items={availabilityHoursItems}
                  onChange={field.onChange}
                  error={!!getError(fieldState)}
                  withoutClear
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Controller
              name="devRate"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput {...field} type="number" error={!!getError(fieldState)} label="Dev rate" color="primary" />
              )}
            />
            <Controller
              name="clientRate"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  {...field}
                  type="number"
                  error={!!getError(fieldState)}
                  label="Client rate"
                  color="primary"
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="rateCommentForDev"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  {...field}
                  type="string"
                  error={getError(fieldState)}
                  label="Rate comment for dev (if any)"
                  color="primary"
                />
              )}
            />
          </Box>
          <Controller
            name="salesComment"
            control={control}
            render={({ field, fieldState }) => (
              <Editor
                initialValue={field.value}
                onSubmit={field.onChange}
                onBlur={field.onBlur}
                error={!!getError(fieldState)}
                label="Comments to sales"
              />
            )}
          />
          <Controller
            name="summary"
            control={control}
            render={({ field, fieldState }) => (
              <Editor
                initialValue={field.value}
                onSubmit={field.onChange}
                onBlur={field.onBlur}
                error={!!getError(fieldState)}
                label="Summary"
              />
            )}
          />
          <Controller
            name="interviewTimeslots"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput {...field} error={!!getError(fieldState)} label="Timeslots for interview" color="primary" />
            )}
          />
          <Controller
            name="readyToStartWhen"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput {...field} error={!!getError(fieldState)} label="Ready to start when" color="primary" />
            )}
          />
          <Controller
            name="chatURL"
            control={control}
            render={({ field, fieldState }) => (
              <LinkInput {...field} error={!!getError(fieldState)} label="Chat url" color="primary" />
            )}
          />
          <Controller
            name="noteForLog"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput {...field} error={!!getError(fieldState)} label="Note" color="primary" />
            )}
          />
        </FieldList>
      </Content>
      <Actions
        submitLabel="Add offer"
        onCancel={onCancel}
        onSubmit={submit}
        disabled={!isValid || addHubspotProjectCandidateLoading}
      />
    </Box>
  );
};

export default memo(OfferProjectForm);
