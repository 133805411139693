import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';

import { useFetchConstants } from 'api/domain/constants';

import { type SpecializationFormValues } from './specialization-form.types';
import Autocomplete from 'components/autocomplete';
import { FormInput, FormSwitch } from 'components/index';
import { getError } from 'utils';

interface UseFormFieldsOptions {
  control: Control<SpecializationFormValues>;
  enableShowInCv: boolean;
}

export const useFormFields = ({
  control,
  enableShowInCv,
}: UseFormFieldsOptions): Array<React.ReactNode | [React.ReactNode, React.ReactNode]> => {
  const { data: constants } = useFetchConstants();

  const { title } = useWatch({ control });

  const fields: Array<React.ReactNode | [React.ReactNode, React.ReactNode]> = [];
  const seniorityItems = (constants?.seniorityLevelsList || []).map((s) => ({ value: s, name: s }));
  const specializationItems = [...(constants?.specializationsList || []), 'Unknown', 'Other'].map((s) => ({
    value: s,
    name: s,
  }));

  fields.push(
    <Controller
      name="seniority"
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          error={!!getError(fieldState)}
          label="Seniority"
          color="primary"
          items={seniorityItems}
          onChange={(value) => {
            field.onChange(value || ' ');
          }}
          value={field.value}
          autoFocus
        />
      )}
    />
  );

  fields.push(
    <Controller
      name="title"
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          error={!!getError(fieldState)}
          label="Specialization"
          color="primary"
          items={specializationItems}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  );

  fields.push(
    <Controller
      name="customTitle"
      control={control}
      render={({ field, fieldState }) =>
        title === 'Other' ? (
          <FormInput
            {...field}
            error={!!getError(fieldState)}
            label="Custom specialization"
            color="primary"
            value={field.value}
          />
        ) : (
          <></>
        )
      }
    />
  );

  if (enableShowInCv) {
    fields.push(
      <Controller
        name="showInCv"
        control={control}
        render={({ field }) => (
          <FormSwitch value={field.value} onChange={field.onChange} label="Show in CV" size="small" />
        )}
      />
    );
  }

  return fields;
};
