import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeIcon from '@mui/icons-material/Mode';

interface GridIconProps {
  onClick: () => void;
  disabled?: boolean;
}

export const DeleteButton: React.FC<GridIconProps> = ({ onClick, disabled }) => (
  <Tooltip title="Switch to delete mode">
    <IconButton
      aria-label="delete"
      size="small"
      sx={{ ml: '5px' }}
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      <DeleteOutlineIcon fontSize="inherit" />
    </IconButton>
  </Tooltip>
);

export const EditButton: React.FC<GridIconProps> = ({ onClick, disabled }) => (
  <Tooltip title="Switch to edit mode">
    <IconButton
      aria-label="delete"
      size="small"
      sx={{ ml: '5px' }}
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      <ModeIcon fontSize="inherit" />
    </IconButton>
  </Tooltip>
);
